<template>
    <div class="d-flex flex-column fill-height">
        <v-col>
            <v-row no-gutters>
                <v-col class="col-9-5 font-weight-bold document-caption d-flex flex-row align-center">{{ Office__title }}</v-col>
                <v-col class="col-2-5 text-right">
                    <img
                        :src="appLogo"
                        class="mb-3 mr-3"
                        width="80px"
                    />
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
                <v-col cols="12" class="text-center text-uppercase border-top-2 border-bottom-2 pa-0 font-weight-bold document-caption">{{ $t('message.shippingInstructions') }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-1">
                <v-col cols="7" class="font-weight-bold">{{ $t('message.purchaseOrder') + " : " + (Contract__version != null ? [Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-') : Contract__title) }}</v-col>
                <v-col cols="5" class="font-weight-bold">{{ $t('message.date') + " : " + formatDate(Sda__sdadate) }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-1">
                <v-col cols="7" class="font-weight-bold" v-if="Contract__supplier_pi_no != null">{{ $t('message.supplierPiNo') + " : " + Contract__supplier_pi_no}}</v-col>
            </v-row>
            <v-row no-gutters class="pt-2" v-if="Addresses.Apptimber.length > 0 && Addresses.Buyer.length > 0">
                <v-col cols="7">
                    <div class="d-flex flex-column">
                        <span v-for="(address, idx) in Addresses.Apptimber" class="d-flex flex-column" v-bind:key="'app-'+idx">
                            <span :class="idx > 0 ? 'pt-2 font-weight-bold' : 'font-weight-bold'">{{ $t('message.' + address.title) }}</span>
                            <span v-html="address.address"></span>
                        </span>
                    </div>
                </v-col>
                <v-col cols="5">
                    <div class="d-flex flex-column">
                        <span v-for="(address, idx) in Addresses.Buyer" class="d-flex flex-column" v-bind:key="'buyer-'+idx">
                            <span :class="idx > 0 ? 'pt-2 font-weight-bold' : 'font-weight-bold'">{{ $t('message.' + address.title) }}</span>
                            <span v-html="address.address"></span>
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-2" v-if="Addresses.Apptimber.length > 0 && Addresses.Buyer.length == 0">
                <v-col cols="7">
                    <div class="d-flex flex-column">
                        <span v-for="(address, idx) in Addresses.Apptimber" class="d-flex flex-column" v-bind:key="'app-'+idx">
                            <span :class="idx > 0 ? 'pt-2 font-weight-bold' : 'font-weight-bold'" v-if="isEven(idx)">{{ $t('message.' + address.title) }}</span>
                            <span v-html="address.address" v-if="isEven(idx)"></span>
                        </span>
                    </div>
                </v-col>
                <v-col cols="5">
                    <div class="d-flex flex-column">
                        <span v-for="(address, idx) in Addresses.Apptimber" class="d-flex flex-column" v-bind:key="'buyer-'+idx">
                            <span :class="idx > 0 ? 'pt-3 font-weight-bold' : 'font-weight-bold'" v-if="isOdd(idx)">{{ $t('message.' + address.title) }}</span>
                            <span v-html="address.address" v-if="isOdd(idx)"></span>
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-2" v-if="Addresses.Buyer.length > 0 && Addresses.Apptimber.length == 0">
                <v-col cols="7">
                    <div class="d-flex flex-column">
                        <span v-for="(address, idx) in Addresses.Buyer" class="d-flex flex-column" v-bind:key="'app-'+idx">
                            <span :class="idx > 0 ? 'pt-2 font-weight-bold' : 'font-weight-bold'" v-if="isEven(idx)">{{ $t('message.' + address.title) }}</span>
                            <span v-html="address.address" v-if="isEven(idx)"></span>
                        </span>
                    </div>
                </v-col>
                <v-col cols="5">
                    <div class="d-flex flex-column">
                        <span v-for="(address, idx) in Addresses.Buyer" class="d-flex flex-column" v-bind:key="'buyer-'+idx">
                            <span :class="idx > 0 ? 'pt-2 font-weight-bold' : 'font-weight-bold'" v-if="isOdd(idx)">{{ $t('message.' + address.title) }}</span>
                            <span v-html="address.address" v-if="isOdd(idx)"></span>
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
                <v-col cols="6" class="d-flex flex-column">
                    <span class="font-weight-bold">{{ $t('message.destinationPort') }}</span>
                    <span v-if="Contract__incoterm_id == 1">{{ Destinationport__title }}{{ Destination__name ? ' / ' + Destination__name : '' }}</span>
                    <span v-else>{{ Destinationport__title }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
                <v-col cols="12" class="d-flex flex-column">
                    <span class="font-weight-bold">{{ $t('message.description') + " " + $t('message.applicableAllDocuments') }}</span>
<!--                    <div v-for="description in Descriptions">{{ generateDescription(description['Salesdescription'],description['Salesitem']) }}</div>-->
                    <div v-for="description in Descriptions" class="d-flex flex-column">
                        <span v-if="lcTerms.includes(Contract__paymentstatement_id) == false">{{ getDescription(description.Salesdescription.title,description.Salesdescription.Mc.id,description.Salesdescription.Mc.title) + getCertificationLabel(description.Salesdescription.title,description.Salesitem.certification_id) + (Destination__code == 'ID' && Contract__productgroup_id != null ? getHsCode(Contract__productgroup_id) : '') }}</span>
                        <span v-else>{{ description.Salesdescription.lcdescription }}</span>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="font-weight-bold">({{ $t('message.doNotIndicatePoNo') }})</v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col cols="12" class="font-weight-bold text-uppercase">{{ $t('message.sdaStandardDocuments') }}</v-col>
                <v-col cols="12">
                    <ol class="pl-2">
                        <li v-for="clause in Clauses['standard'] " v-bind:key="clause.Clause.id" class="pl-4">{{ clause.Clause.title }}</li>
                    </ol>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col cols="12" class="font-weight-bold text-uppercase">{{ $t('message.sdaCountryCustomerDocuments') }}</v-col>
                <v-col cols="12">
                    <ol class="pl-2">
                        <li v-for="clause in Clauses['specific'] " v-bind:key="clause.Clause.id" class="pl-4">{{ clause.Clause.title }}</li>
                    </ol>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col cols="12" class="font-weight-bold text-uppercase">{{ $t('message.sdaOtherRequests') }}</v-col>
                <v-col cols="12">
                    <ol class="pl-2">
                        <li v-for="clause in Clauses['other'] " v-bind:key="clause.Clause.id" class="pl-4">{{ clause.Clause.title }}</li>
                    </ol>
                </v-col>
            </v-row>
            <div id="page-break" style="page-break-after:always" v-if="Sda__longform == 1"></div>
            <v-row no-gutters class="pt-4">
                <v-col cols="12" class="font-weight-bold">
                    <span class="text-uppercase">{{ $t('message.sdaPresentationDocuments') }}</span>
                    <span v-html="', ' + $t('message.sendOriginalDocuments')" v-if="!hasCustomDeliveryAddress"></span>
                </v-col>
                <v-col cols="12" class="d-flex flex-column">
                    <ol class="pl-2">
                        <li v-for="clause in Clauses['presentation'] " v-bind:key="clause.Clause.id" class="pl-4" v-html="nl2br(clause.Clause.title)"></li>
                    </ol>
                    <span class="pt-1" v-html="Deliveryaddress"></span>
                </v-col>
            </v-row>
        </v-col>
        <h1 id="loaded" v-if="loaded" style="margin-bottom: 0px !important;"></h1>
    </div>
</template>

<script>
import AppConfig from "../../../constants/AppConfig";
import { api } from "../../../api";
import {formatDate, isEven, isOdd, log} from "Helpers/helpers";
import {
    defaultDeliveryAddresses,
    // certificationsNew,
    // productGroupsNew
} from "../../../store/modules/appic/constants"
import detectNewline from "detect-newline";
import {nl2br} from "Helpers/helpers";

export default {
    name: "PrintSda",
    props: ['sdaId'],
    data() {
        return {
            appLogo: AppConfig.appSessionLogo,
            certificationsNew: [],
            // certificationsNew: certificationsNew,
            defaultDeliveryAddresses:  defaultDeliveryAddresses,
            productGroupsNew: [],
            // productGroupsNew: productGroupsNew,
            hasCustomDeliveryAddress: false,
            lcTerms: [],
            // lcTerms: [4029,4030,4096,4219,4276,4302,4317,4318,4347,4378,4431,4212,4335],
            loaded: false,
            Addresses: {
                Apptimber: [],
                Buyer: []
            },
            Contract__hscode: null,
            Contract__incoterm_id: null,
            Contract__partial_no: null,
            Contract__paymentstatement_id: null,
            Contract__productgroup_id: null,
            Contract__revision_no: null,
            Contract__salestype_id: null,
            Contract__supplier_pi_no: null,
            Contract__title: null,
            Contract__version: null,
            Clauses: [],
            Descriptions: [],
            Destination__code: null,
            Destination__name: null,
            Deliveryaddress: null,
            Deliveryaddress_title: null,
            Destinationport__title: null,
            Sda__contract_id: null,
            Sda__deliveryaddress_id: null,
            Sda__longform: 0,
            Sda__sdadate: null,
            Office__title: null,
            Office__address1: null,
            Office__address2: null,
            Office__address3: null,
            Office__city: null,
            Office__companyno: null,
            Office__postcode: null,
            Office__state: null,
            Office__country: null
        }
    },
    computed: {},
    methods: {
        checkAddressForCommissionSalesOverride(sdaId) {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/sdas/" + sdaId + "/presentation-address-override")
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        detectNewline,
        formatDate,
        getCertificationLabel(salesDescription, certificationId){
            if(certificationId != null && certificationId != 0){
                let certificationText = this.certificationsNew.find(c => c.Certification.id == certificationId)?.Certification?.title

                let searchStrings =  ['FSC','PEFC','SVLK',certificationText];
                let stringFound = false

                searchStrings.forEach(string => {
                    if(salesDescription.indexOf(string) > 0) stringFound = true
                })

                if(stringFound){
                    return ''
                } else {
                    return ', ' + certificationText
                }

            } else {
                return ''
            }
        },
        getCertifications() {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/certifications")
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getDescription(salesDescription, mcId, mcText) {
            if(mcId != null){
                let searchStrings =  ['AD','AST','Green','green','KD',mcText];
                let stringFound = false

                searchStrings.forEach(string => {
                    if(salesDescription.indexOf(string) > 0) stringFound = true
                })

                if(stringFound){
                    return salesDescription
                } else {
                    return salesDescription + ' ' + mcText
                }
            } else {
                return salesDescription
            }
        },
        getHsCode (val) {
            const productGroup = this.productGroupsNew.find(p => {
                if(p.hasOwnProperty('header') == false && p?.ProductGroup.id == val){
                    return true
                } else {
                    return  false
                }
            })
            if(productGroup?.ProductGroup.hscode){
                return ", " + this.$t('message.hsCodeForImport') + ": " + productGroup?.ProductGroup.hscode
            } else {
                return ''
            }
        },
        getLcBasedPaymentTerms() {
            return new Promise((resolve, reject) => {
                log('get lc based payment terms')
                api
                    .get("/print/paymentterms/lc/ids",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getProductGroups() {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/productgroups",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        isEven,
        isOdd,
        loadAddressesBySda (sda_id) {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/sdas/" + sda_id + "/addresses",{
                    })
                    .then(response => {
                        this.Addresses.Apptimber = response.data.data['apptimber']
                        this.Addresses.Buyer = response.data.data['buyer']
                        resolve(sda_id)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        generateDescription (descr_obj, item_obj) {
            let description = ''
            if(descr_obj['Species']['title']) description += descr_obj['Species']['title']
            if(descr_obj['Spec']['title']) description += ', ' + descr_obj['Spec']['title']
            if(descr_obj['Mc']['title']) description += ', ' + descr_obj['Mc']['title']

            //not sure about this one 2021-03-24 VJC
            if(descr_obj['othertext'] != '' && descr_obj['othertext'] != null && descr_obj['othertext'] != descr_obj['Spec']['title']) description += descr_obj['Species']['title'] + ", " + descr_obj['othertext']

            if(item_obj['description'] != null && item_obj['description'] != ''){
                description = descr_obj['Species']['title'] + ', ' + item_obj['description']
            }

            if(this.Destination__code == 'ID'){
                if(this.Contract__hscode != null) {
                    description += "  " + this.$t('message.hsCodeForImport') + ": " + this.Contract__hscode
                }
            }

            if(descr_obj['lcdescription'] != null && descr_obj['lcdescription'].length > 0) description = descr_obj['lcdescription'].trim()

            return description
        },
        loadClausesBySda (sda_id) {
            let self = this
            return new Promise((resolve, reject) => {
                api
                    .get("/print/sdas/" + sda_id + "/clauses",{
                    })
                    .then(response => {
                        self.Clauses = response.data.data
                        resolve(sda_id)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        loadDescriptionsByContract (contract_id) {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/contracts/" + contract_id + "/salesdescriptions",{
                    })
                    .then(response => {
                        //this.Descriptions = response.data.data
                        //ensure no double entries
                        let descriptions = []
                        response.data.data.forEach(description => {
                            if(descriptions.includes(description.Salesdescription.title) == false){
                                descriptions.push(description.Salesdescription.title)
                                this.Descriptions.push(description)
                            }
                        })
                        resolve(contract_id)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        loadSdaById (sda_id) {
            let self = this
            return new Promise((resolve, reject) => {
                api
                    .get("/print/sdas/" + sda_id,{
                    })
                    .then(response => {
                        for(let key in response.data.data[0]){
                            self[key] = response.data.data[0][key]
                        }
                        resolve(sda_id)
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(()=>{})
            })
        },
        nl2br
    },
    created () {
        this.getProductGroups()
            .then((productGroups)=>{
                this.productGroupsNew = productGroups
            })
            .catch()
        this.getLcBasedPaymentTerms()
            .then((lcPaymentTerms) => {
                this.lcTerms = lcPaymentTerms
            })
            .catch()
        this.getCertifications()
            .then((certifications) => {
                this.certificationsNew = certifications
            })
        if(this.$route.matched.some(({name}) => name === 'print_sda')){
            this.loadSdaById(this.sdaId)
                .then(() => {
                    this.loadClausesBySda(this.sdaId).then(()=>{
                        this.loadDescriptionsByContract(this.Sda__contract_id).then(()=>{
                            this.Clauses['presentation'].forEach(clause => {
                                if(clause.Clause.template_id == 10841) this.hasCustomDeliveryAddress = true
                            })
                            let address = this.defaultDeliveryAddresses.find(address => address.Deliveryaddress.id == this.Sda__deliveryaddress_id)
                            if(address != null && !this.hasCustomDeliveryAddress) {
                                this.Deliveryaddress = address?.Deliveryaddress?.title + "<br>" + address?.Deliveryaddress?.address
                            }
                            this.loadAddressesBySda(this.Sda__id)
                                .then(()=>{
                                    if(this.Deliveryaddress == null) {
                                        this.checkAddressForCommissionSalesOverride(this.Sda__id)
                                            .then((overrideAddresses) => {
                                                if (overrideAddresses[0] != null) {
                                                    this.Deliveryaddress = overrideAddresses[0]
                                                }
                                                this.loaded = true
                                            })
                                            .catch(() => {
                                                this.loaded = true
                                            })
                                    } else {
                                        this.loaded = true
                                    }
                                })
                                .catch(() => {
                                    this.loaded = true
                                })
                        })
                    })
                })
        }
    },
}
</script>

<style>
@font-face {
    font-family: 'AppicArial';
    src: url('/static/fonts/Arial.woff2') format('woff2'),
    url('/static/fonts/Arial.woff2') format('woff'),
    url('/static/fonts/Arial.woff2') format('truetype');
}
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.col-3-5 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
}
.col-4-5 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
}
.col-9-5 {
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
}
.border-top-1 {
    border-top: 1px solid black !important;
}
.border-bottom-1 {
    border-bottom: 1px solid black !important;
}
.border-top-2 {
    border-top: 2px solid black;
}
.border-bottom-2 {
    border-bottom: 2px solid black !important;
}
.document-caption {
    font-size: 14px !important;
    line-height: 16px;
}
.footer-note {
    font-size: 8pt !important;
}
.header-title {
    font-size: 18pt !important;
    line-height: 22px;
    font-weight: bold !important;
}
.header-subtitle {
    font-size: 10pt !important;
}
.v-application{
    font-family: 'AppicArial' !important;
    line-height:13px !important;
    font-size:8pt !important;
    padding:2px !important;
}
body {
    width: 18.5cm !important;
    margin: 0 !important;
}
</style>